import { Injectable, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, BehaviorSubject, throwError, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AppConfigData } from './../core/config/appConfigData';
import { ConfirmationModal } from './modals/common';
import { LoaderService } from '../core/services/loader.service';
import { SweetAlertIcon } from './constants/enum';
import { SortDescriptor } from '@progress/kendo-data-query';
import { process, State, toODataString } from '@progress/kendo-data-query';
import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';
import { LocalStorageService } from '../core/services/localStorage.service';
import { catchError } from 'rxjs/operators';
import { Validations } from './constants/validations';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  configData: any;
  private currentUserSubject: BehaviorSubject<any>;
  public selectableSettings!: SelectableSettings;
  ServiceError = Validations.ServiceError;
  public checkboxOnly = true;
  public drag = false;
  public mode: SelectableMode = 'multiple';
  consumerWalletId: any;
  private walletHistoryDetails: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public walletHistory$: Observable<any> = this.walletHistoryDetails.asObservable();

  private meterViewDetails: any;
  private consumptionViewDetails: any;


  private billbetaildbyId: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public billDetails$: Observable<any> = this.billbetaildbyId.asObservable();

  public setbilldeatilsById(bills: any): void {
    this.billbetaildbyId.next(bills);
    console.log(bills);
  }
  public billdeatilsByIdeclear(): void {
    this.billbetaildbyId.next(null);
  }

  public setwalletHistoryById(wallet: any): void {
    this.walletHistoryDetails.next(wallet);
    console.log(wallet);
  }
  private filters: { [key: string]: CompositeFilterDescriptor } = {};
  private filterState!: CompositeFilterDescriptor;

  setFilters(page: string, filter: CompositeFilterDescriptor) {
    console.log(`Setting filter for ${page}:`, filter);
    this.filters[page] = filter;
  }
  clearAllFilters() {
    console.log('Clearing all filters');
    this.filters = {};
  }
  getFilters(page: string): CompositeFilterDescriptor | null {
    return this.filters[page] || null;
  }
  setFilter(filter: CompositeFilterDescriptor) {
    console.log(filter);
    this.filterState = filter;

  }
  walletbyId: any
  getWalletByIdDetails() {
    return this.walletbyId;
  }

  setWalletDetailsById(data: any) {
    this.walletbyId = data; // Emit data to subscribers
  }


  getFilter(): CompositeFilterDescriptor {
    return this.filterState;
  }

  public walletHistoryIdeclear(): void {
    this.walletHistoryDetails.next(null);
  }
  constructor(
    private http: HttpClient,
    private router: Router,
    private loaderService: LoaderService,
    private localStorageService: LocalStorageService
  ) {
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };
    const currentUser = this.getCurrentUserFromLocalStorage();
    this.currentUserSubject = new BehaviorSubject<any>(currentUser);
  }

  getCurrentUserFromLocalStorage(): any {
    const currentUserString = this.localStorageService.getItem('currentUser');
    return currentUserString ? JSON.parse(currentUserString) : null;
  }

  updateCurrentUserToken(
    newToken: string,
    refreshToken: string,
    tokenExpiresOn: string
  ): void {
    const currentUser = this.getCurrentUserFromLocalStorage();
    if (currentUser) {
      currentUser.token = newToken;
      currentUser.refreshToken = refreshToken;
      currentUser.tokenExpiresOn = tokenExpiresOn;
      this.localStorageService.setItem(
        'currentUser',
        JSON.stringify(currentUser)
      );
    }
  }

  showConfirmationDialog(modalInfo: ConfirmationModal): Promise<any> {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    return swalWithBootstrapButtons.fire({
      title: modalInfo.title,
      text: modalInfo.text,
      icon: modalInfo.icon,
      showCancelButton: modalInfo.showCancelButton,
      confirmButtonText: modalInfo.confirmButtonText,
      cancelButtonText: modalInfo.cancelButtonText,
    });
  }

  showMessageDialog(
    title: string,
    message: string,
    icon: SweetAlertIcon
  ): void {
    Swal.fire(title, message, icon);
  }
  closeDialog() {
    Swal.close();
  }


  showConfirmMessageDialog(
    title: string,
    message: string,
    icon: SweetAlertIcon
  ): Promise<any> {
    // Return SweetAlert promise to handle user response
    return Swal.fire({
      title: title,
      text: message,
      icon: icon,
      showCancelButton: true, // Show 'Cancel' button
      confirmButtonText: 'Yes, proceed',
      cancelButtonText: 'No, stay here',
    });
  }

  // getAllConfigData(reload?: boolean): Observable<any> {
  //   this.loaderService.setLoading(true);
  //   if (!this.configData || reload) {
  //     return this.http.get<any>(AppConfigData.configUrl).pipe(
  //       tap((data) => {
  //         this.configData = data;
  //         this.loaderService.setLoading(false);
  //       })
  //     );
  //   } else {
  //     this.loaderService.setLoading(false);
  //     return of(this.configData);
  //   }
  // }

  getAllConfigData(reload?: boolean): Observable<any> {
    const headers = new HttpHeaders({
      projectIdRequired: 'true', // Custom header to skip interceptor
    });
    this.loaderService.setLoading(true);
    // if (!this.configData || reload) {
    return this.http.get<any>(AppConfigData.configUrl, { headers }).pipe(
      tap((data) => {
        this.configData = data;
        this.loaderService.setLoading(false);
      })
    );
    // } else {
    //   this.loaderService.setLoading(false);
    //   return of(this.configData);
    // }
  }
  getBillsAllConfigData(reload?: boolean): Observable<any> {
    const headers = new HttpHeaders({
      projectIdRequired: 'true', // Custom header to skip interceptor
    });

    // if (!this.configData || reload) {
    return this.http.get<any>(AppConfigData.configUrl, { headers }).pipe(
      tap((data) => {
        this.configData = data;

      })
    );
    // } else {
    //   this.loaderService.setLoading(false);
    //   return of(this.configData);
    // }
  }



  updateRole(role: string, instaceFlag?: boolean): void {
    const currentUserString = this.localStorageService.getItem('currentUser');
    if (currentUserString) {
      const currentUser = JSON.parse(currentUserString);
      if (currentUser.currentUserRoleDetails) {
        currentUser.currentUserRoleDetails.role = role;
        currentUser.activeInstance = instaceFlag;
        this.consumerWalletId =
          currentUser.currentUserRoleDetails?.consumerWalletId;
        this.setwalletHistoryById(this.consumerWalletId);
        this.localStorageService.setItem(
          'currentUser',
          JSON.stringify(currentUser)
        );
        this.currentUserSubject.next(currentUser); // Update the BehaviorSubject
      }
    } else {
      console.error('currentUser not found in localStorage');
    }
  }

  getCurrentUser(): Observable<any> {
    return this.currentUserSubject.asObservable();
  }

  createOrderByQuery(sortDescriptors: SortDescriptor[]): string {
    if (!sortDescriptors || sortDescriptors.length === 0) {
      return '';
    }

    const orderByClauses = sortDescriptors.map((descriptor) => {
      // Handle cases where dir or field might be undefined
      const field = descriptor.field?.replace(/\./g, '/') ?? '';
      const dir = descriptor.dir ?? 'asc';
      return `${field} ${dir}`;
    });

    return `$orderby=${orderByClauses.join(',')}`;
  }

  createQuery(state: State): string {
    const queryString = `${toODataString(state)}&$count=true`;
    console.log(queryString);

    let filterQuery = '';

    const filterIndex = queryString.indexOf('$filter=');
    if (filterIndex !== -1) {
      const filterEndIndex = queryString.indexOf('&', filterIndex);
      if (filterEndIndex !== -1) {
        filterQuery = queryString.substring(filterIndex, filterEndIndex);
      } else {
        filterQuery = queryString.substring(filterIndex);
      }
    }

    console.log(filterQuery);

    return filterQuery;
  }

  getTemplates(fileName: string, projectId: string): Observable<any> {
    let url;
    if (fileName === 'ResidentailUnits.xlsx') {
      url = `${AppConfigData.getTemplatesResidentailUnitUrl}?ProjectId=${projectId}`;
    } else {
      url = `${AppConfigData.getTemplatesUrl}?fileName=${fileName}&ProjectId=${projectId}`;
    }

    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        catchError((error) => {
          console.error('getTemplates failed:', error);

          // Check if the error is a Blob and parse it
          if (error.error instanceof Blob) {
            // Read the Blob as text and parse the JSON
            const reader = new FileReader();
            reader.onload = () => {
              const errorMessage = this.extractErrorMessage(reader.result as string);
              this.showMessageDialog(SweetAlertIcon.ERROR, errorMessage, SweetAlertIcon.ERROR);
            };
            reader.readAsText(error.error);
          } else {
            // Handle other types of errors
            const errorMessage = error.message || 'An unknown error occurred';
            this.showMessageDialog(SweetAlertIcon.ERROR, errorMessage, SweetAlertIcon.ERROR);
          }

          // Return the error so it can be handled further up if needed
          return throwError(error);
        })
      );
  }

  // Function to extract error message from JSON string
  private extractErrorMessage(responseText: string): string {
    try {
      const parsed = JSON.parse(responseText);
      return parsed?.message || 'An unknown error occurred';
    } catch (e) {
      return 'Error parsing the error response';
    }
  }


  /* getTemplates(fileName: string, projectId: string): Observable<any> {
    let url
    if (fileName == 'ResidentailUnits.xlsx') {
      url = `${AppConfigData.getTemplatesResidentailUnitUrl}?ProjectId=${projectId}`
    } else {
      url = `${AppConfigData.getTemplatesUrl}?fileName=${fileName}&ProjectId=${projectId}`
    }
    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        // Assuming the body is JSON
        catchError((error) => {
          console.error('getTemplates failed:', error);
          this.showMessageDialog(SweetAlertIcon.ERROR, error.message, SweetAlertIcon.ERROR);
          return throwError(error);
        })
      );
  } */

  getTemplatesMetres(fileName: string, projectId: string): Observable<any> {
    let url

    url = `${AppConfigData.getTemplatesResidentailUnitMeterUrl}?ProjectId=${projectId}`

    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        // Assuming the body is JSON
        catchError((error) => {
          console.error('getTemplates failed:', error);
          this.showMessageDialog(SweetAlertIcon.ERROR, this.ServiceError.Error, SweetAlertIcon.ERROR);
          return throwError(error);
        })
      );
  }

  getConsumerTemplate(fileName: string, projectId: string): Observable<any> {
    let url
    url = `${AppConfigData.getTemplatesResidentialUnitOccupantUrl}?ProjectId=${projectId}`

    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        // Assuming the body is JSON
        catchError((error) => {
          console.error('getTemplates failed:', error);
          this.showMessageDialog(SweetAlertIcon.ERROR, this.ServiceError.Error, SweetAlertIcon.ERROR);
          return throwError(error);
        })
      );
  }

  /* downloadPaymentReceipt(id: any, fileName: string): Observable<any> {
    return this.http
      .post(`${AppConfigData.downloadPaymentReceipt + id}`) .pipe(

      
        catchError((error) => {
          console.error('getTemplates failed:', error);
          this.showMessageDialog(SweetAlertIcon.ERROR, this.ServiceError.Error, SweetAlertIcon.ERROR);
          return throwError(error);
        })
      );
  } */

  downloadPaymentReceipt(paymentData: any,): Observable<Blob> {
    console.log(paymentData)
    if (paymentData.isInvoice) {
      var url = `${AppConfigData.downloadInvoiceReceipt + paymentData.id}`
    } else {
      var url = `${AppConfigData.downloadPaymentReceipt + paymentData.id}`
    }
    return this.http.post(url, null, {
      responseType: 'blob' // Specify the response type as 'blob'
    }).pipe(
      catchError((error) => {
        console.error('getTemplates failed:', error);
        this.showMessageDialog(SweetAlertIcon.ERROR, this.ServiceError.Error, SweetAlertIcon.ERROR);
        return throwError(error);
      })
    );
  }


  downloadBillsPaymentrecceipt(paymentData: any,): Observable<Blob> {
    console.log(paymentData)

    let url;
    url = `${AppConfigData.downloadBillPaymentReceipt + paymentData.paymentId}`


    return this.http.post(url, null, {
      responseType: 'blob' // Specify the response type as 'blob'
    }).pipe(
      catchError((error) => {
        console.error('getTemplates failed:', error);
        this.showMessageDialog(SweetAlertIcon.ERROR, this.ServiceError.Error, SweetAlertIcon.ERROR);
        return throwError(error);
      })
    );

  }
  downloadBillsPayment(paymentData: any,): Observable<Blob> {
    console.log(paymentData)
    let url;
    /*  let url;
     if (paymentData && paymentData.status && paymentData.status.toLowerCase() === 'paid') {
       url = `${AppConfigData.downloadBillPaymentReceipt + paymentData.paymentId}`
     } else {
       console.log(paymentData)
       url = `${AppConfigData.downloadBillPdf + paymentData.id}`
     } */
    url = `${AppConfigData.downloadBillPdf + paymentData.id}`
    return this.http.post(url, null, {
      responseType: 'blob' // Specify the response type as 'blob'
    }).pipe(
      catchError((error) => {
        console.error('getTemplates failed:', error);
        this.showMessageDialog(SweetAlertIcon.ERROR, this.ServiceError.Error, SweetAlertIcon.ERROR);
        return throwError(error);
      })
    );

  }
  dateFormateChnage(inputDate: any) {
    const date = new Date(inputDate);

    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // 12-hour format with AM/PM
    };
    const formattedDate = date.toLocaleString('en-GB', options).replace(',', '').replace(' ', ' ');
    return formattedDate
  }



  metersbulkUpload(id: any, file: File): Observable<HttpResponse<Blob>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    let url = `${AppConfigData.getResidentialUnitswisetMeterUrl + id}`
    return this.http.post(url, formData, {
      responseType: 'blob',
      observe: 'response',
    })
      .pipe(
        catchError((error) => {
          console.error('bulkUpload failed:', error);
          this.showMessageDialog(
            'bulkUpload failed',
            this.ServiceError.Error,
            SweetAlertIcon.ERROR
          );
          return throwError(error);
        })
      );
  }

  consumersbulkUpload(id: any, file: File): Observable<HttpResponse<Blob>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    let url = `${AppConfigData.getResidentialUnitswisetConsumersUrl + id}`
    return this.http.post(url, formData, {
      responseType: 'blob',
      observe: 'response',
    })
      .pipe(
        catchError((error) => {
          console.error('bulkUpload failed:', error);
          this.showMessageDialog(
            'bulkUpload failed',
            this.ServiceError.Error,
            SweetAlertIcon.ERROR
          );
          return throwError(error);
        })
      );
  }




  bulkUpload(url: string, file: File): Observable<HttpResponse<Blob>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(`${url}`, formData, {
      responseType: 'blob',
      observe: 'response',
    })
      .pipe(
        catchError((error) => {
          console.error('bulkUpload failed:', error);
          this.showMessageDialog(
            'bulkUpload failed',
            this.ServiceError.Error,
            SweetAlertIcon.ERROR
          );
          return throwError(error);
        })
      );
  }









  exportReports(payload: any) {
    const headers = new HttpHeaders({
      // 'No-Intercept': 'true', // Custom header to skip interceptor
    });

    // Build the URL
    let url = `${AppConfigData.exportReportsUrl}`;

    // Append ProjectIds if they exist and are not 'all'
    if (payload?.ProjectIds && payload?.ProjectIds.length) {
      url += payload?.ProjectIds !== 'all'
        ? `?${payload.ProjectIds.map((id: string) => `ProjectIds=${encodeURIComponent(id)}`).join('&')}`
        : '';
    }

    // Append BatteryStatus if it exists
    if (payload?.BatteryStatus) {
      url += `${url.includes('?') ? '&' : '?'}BatteryStatus=${encodeURIComponent(payload.BatteryStatus)}`;
    }

    // Append Utility if it exists
    if (payload?.Utility) {
      url += `${url.includes('?') ? '&' : '?'}Utility=${encodeURIComponent(payload.Utility)}`;
    }

    // Make the HTTP GET request
    this.http
      .get(url, {
        headers,
        responseType: 'blob', // We expect a binary response (blob)
      })
      .subscribe(
        (blob) => {
          this.loaderService.setLoading(false);
          // Create a link element for the file download
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'Reports'; // Set the download filename

          // Append the link to the body, click it, then remove it
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        (error) => {
          this.showMessageDialog(
            error.error.message,
            this.ServiceError.Error,
            SweetAlertIcon.ERROR
          );
          this.loaderService.setLoading(false);
          console.error('Error downloading the report: ', error);
        }
      );
  }


  setMeterViewDetails(data: any) {
    this.meterViewDetails = data;
  }

  getMeterViewDetails() {
    return this.meterViewDetails;
  }

  setConsumptionViewDetails(data: any) {
    this.consumptionViewDetails = data;
  }

  getConsumptionViewDetails() {
    return this.consumptionViewDetails;
  }


  downloadUtilityFiles(fileUrl: any, fileName: string) {
    this.loaderService.setLoading(true);
    const headers = new HttpHeaders({
      'No-Intercept': 'true', // Custom header to skip interceptor
    });
    this.http.get(fileUrl, { headers, responseType: 'blob' }).subscribe(
      (blob) => {
        this.loaderService.setLoading(false);
        // Create a link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName; // Set the download filename

        // Append link to the body, click it, and then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      (error) => {
        this.loaderService.setLoading(false);
        this.showMessageDialog(
          error.error.message,
          this.ServiceError.Error,
          SweetAlertIcon.ERROR
        );
        console.error('Error downloading the image: ', error);
      }
    );
  }
}
